import { useEffect, useState } from "react";
// import useMediaQuery from "./hooks/useMediaQuery";
import Navbar from "./scenes/Navbar";
import Footer from "./scenes/Footer";
import { Amplify } from "aws-amplify";
import awsmobile from './aws-exports';


import ServiceMenu from "./scenes/services/ServiceMenu";
import Home from "./scenes/Home";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Confirmation from "./scenes/checkout/Confirmation";
import Checkoutform from "./scenes/checkout/CheckoutForm";
import Testimonials from "./scenes/Testimonials";
import Contact from "./scenes/Contact";
import Services from "./scenes/Services";
import About from "./scenes/About";
import Appointments from "./scenes/checkout/Appointments";
import { Provider } from "react-redux";
import store from "./state/store";
import Summary from "./scenes/checkout/Summary";
import Test from "./scenes/Test";
import Gallery from "./scenes/Gallery";
import ImageCollage from "./scenes/ImageCollage";




import config from './aws-exports';



// Amplify.configure(config);

function App() {
  
  return (

<div className="app">
    
    <Router>   
    {/* <Provider store={store}>     */}
      <Navbar />           
           

          <Provider store={store}> 
  
            
            <Routes>        

        

            <Route path="/" element={<Home/>}/>
                <Route path="book" element={<ServiceMenu/>}/>
                <Route path="about" element={<About/>}/>
                {/* <Route path="test" element={<Test/>}/> */}
                <Route path="testimonials" element={<Testimonials/>}/>
                <Route path="contact" element={<Contact/>}/>
                <Route path="services" element={<Services/>}/>
                <Route path="gallery" element={<Gallery/>}/>
                <Route path="confirmation" element={<Confirmation/>}/>
                  <Route path="checkoutform" element={<Checkoutform/>}/>
                  <Route path="appointments" element={<Appointments/>}/>
                  <Route path="summary" element={<Summary/>}/>
                  <Route path="imagecollage" element={<ImageCollage/>}/>
        
            </Routes>
       
            </Provider>
      </Router> 

    <div>
    <Footer/>
    </div>

   
  
</div>


);
}

export default App;
