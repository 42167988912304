import React from "react";
import { differenceInDays } from 'date-fns';


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import TimePicker from 'react-time-picker';
import timezone from 'dayjs/plugin/timezone';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { useEffect, useState } from "react";

import { useSelector } from "react-redux";


import { Link } from "react-router-dom";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';

import { DateRange } from 'react-date-range';

import { useDispatch } from "react-redux";




import {
    addToPets,
    addToDays,
    addToTimes,
    addToDates,
    addToPickUp,
    addToDropOff,
    addToWalkDuration
  } from "../../state"



// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('America/New_York');

const Appointments = () => {
  
  
  useEffect(() => {
    window.scrollTo(0,0)

  }, [])

    const dispatch = useDispatch();



    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),        
        key: 'selection',
        
      }
      
      

    const items = useSelector(state=> state.cart.cart)
    // console.log(items)
    let serviceName = '';

    if (items.length > 0) {

      serviceName = items[items.length-1].services.name;
    }


      

    const [pickUpDate, setPickUpDate] = useState(selectionRange);
    const [dropOffDate, setDropOffDate] = useState(selectionRange);

    const numDays = differenceInDays(pickUpDate.endDate,dropOffDate.startDate) + 1;

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('')

    const handleStartTime = (start) => {
  
      setStartTime(dayjs(start).format('hh:mm:A'))
 
   
    }

    const handleEndTime = (end) => {
 
      setEndTime(dayjs(end).format('hh:mm:A'))
    }
    
    console.log(startTime,endTime)
     
    
  
    const [numPets, setNumPets] = useState(1);
    const [pickUp, setPickUp] = useState("No");
    const [dropOff, setDropOff] = useState("No");
    const [walkTime, setWalkTime] = useState("30 mins");

      const handleChange = (ranges) => {
        
        setPickUpDate(ranges.selection)
        setDropOffDate(ranges.selection)
       
      }
      // console.log(pickUpDate, dropOffDate)
      
      

      const handleClick = () => {
        const dates = {
          pickUpDate: pickUpDate.startDate,
          endDate: pickUpDate.endDate
        }

        dispatch(addToPets({numPets})) 
        dispatch(addToDays({numDays})) 
        dispatch(addToDates({dates})) 
        dispatch(addToTimes({startTime,endTime}))
        dispatch(addToPickUp({pickUp}))
        dispatch(addToDropOff({dropOff}))
        dispatch(addToWalkDuration({walkTime}))

   
    }


    const handlePets = event => {
      // console.log(event.target.value);
      setNumPets(event.target.value);
      
    };

    const handleWalkTime = event => {
      // console.log(event.target.value);
      setWalkTime(event.target.value);
      
    };

    const handlePickUp = event => {
      // console.log(event.target.value);
    
      if (event.target.value === "Both ($50)") {
          setPickUp("Yes")
          setDropOff("Yes")

      }

      if (event.target.value === "Pick Up ($25)") {
        setPickUp("Yes")      

    }
    if (event.target.value === "Drop Off ($25)") {
      setDropOff("Yes")      

    }


      
    };



    // console.log(pickUpTime)
    const dates = useSelector(state=> state.cart.dates)

    const date = new Date()
    const tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate())

  
    return(

   
        <div className="py-32 mx-auto w-5/6 bg-white rounded-lg shadow-lg p-6">
                {/* <Checkoutform/> */}

                {/* <div className="flex justify-center font-playfair text-black font-bold md:text-xl text-md pb-10" >

                     {serviceName}
                    </div>   */}

                           <div className="flex justify-center font-semibold font-playfair pb-2 text-md">How many pets? </div>

                  <div className="flex justify-center pb-2 font-semibold text-md">
                    
                      <select ngValue={numPets} onChange={handlePets} className="bg-yellow">
                          
                          <option >1</option>
                          <option >2</option>     
                          <option >3</option>  
                                  
                      </select>

                      </div>  

                      {serviceName === "Dog Walk" && 
                      
                      <div className="flex justify-center pb-2 font-semibold text-md">Walk duration </div> 
                      } 

                      {serviceName === "Dog Walk" && 
                      
                

                          <div className="flex justify-center pb-5 font-semibold text-md"> 
                                  
                          <select ngValue={walkTime} onChange={handleWalkTime} className="bg-yellow">
                                  
                                <option >30 mins</option>
                                <option >60 mins</option>     
                              
                                
                          </select>
                        </div>
                      
                      } 

                      { (serviceName === "Boarding" ||serviceName === "Daycare") &&
                      
                      <div className="flex justify-center font-semibold font-playfair pb-5 text-md">Do you need a pick up or drop off?</div> }


                      { (serviceName === "Boarding" || serviceName === "Daycare") &&
                      <div className="flex justify-center pb-5 font-semibold text-md ">
                         
                          <select ngValue={pickUp} onChange={handlePickUp} className="bg-yellow">
                              
                              <option >None</option>
                              <option >Pick Up ($25)</option> 
                              <option >Drop Off ($25)</option>    
                              <option >Both ($50)</option>        
                              
                                      
                          </select>



                          </div>   

                      
                      } 

             
                            {/* <div className="flex justify-center font-semibold font-playfair pb-5 text-md">Do you need a drop off? ($25) </div>

                          <div className="flex justify-center pb-5 font-semibold text-md">
                            
                              <select ngValue={dropOff} onChange={handleDropOff}>
                                  
                                  <option >No</option>
                                  <option >Yes</option>     
                                  
                                          
                              </select>

                              </div>    */}

                      
                <div className="flex justify-center font-playfair text-black font-semibold text-md pb-1">

                Which dates do you need? 
                </div>   

   

                        
                <div className="flex justify-center">                    
                     
                        <DateRange
                        
                          ranges={[pickUpDate]}
                          minDate={tomorrow}                     
                 
                         onChange={handleChange}
                                         
                        />   
                  </div>

                  {/* RENDER Time */}

                  <div className="">

                      {  (serviceName === "Meet and Greet" || serviceName === "Drop in Visit" || serviceName === "Dog Walk") &&
                 
                        <div className="flex justify-center pt-5">
                            
                          <LocalizationProvider dateAdapter={AdapterDayjs}> 

                            <TimePicker 
                              label="What Time?"
                              // timezone={"America/New_York"}
                              value={''}
                              ampm={true}
                              onChange={newValue => {handleStartTime(newValue)}} /> 
                          </LocalizationProvider>
                               

                        </div>
                      
                      
                      }      

                  </div>

                





                 
                  
                  <div className="flex justify-center p-5">

                      {  (serviceName === "Daycare" ||  serviceName === "Boarding") &&
                 
                        <div className="space-y-2 ">
                          <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}> 
                          
                            <TimePicker label="Drop Off Time"
                              // maxTime={()=>new Date(0, 0, 0, 18, 45)}
                              // timezone="America/New_York"
                              value={''}
                              ampm={true}
                              onChange={newValue => handleStartTime(newValue)} /> 
                            
                            {/* <TimePicker className="" label="Choose Pickup Time" />  */}
                            </LocalizationProvider>
                            </div>
                        
                          <div>
                            
                              <LocalizationProvider dateAdapter={AdapterDayjs}> 

                                                          
                                <TimePicker className="text-sm" label="Pick up Time"
                                // timezone="America/New_York"
                                value={''}
                                ampm={true}
                                onChange={newValue => handleEndTime(newValue)} /> 

                                </LocalizationProvider>


                          </div>
                                  
                     

                        </div> 

               
                      
                      
                      } 

                    

                  </div>

                




           
                    
             {/* <div className=" flex justify-center text-center"> */}

             {/* <button type="button" className=' rounded-lg p-5 bg-salmon font-semibold text-black mt-5 text-md hover:bg-salmon hover:text-black transition duration-500'
                href="/"
                >
                back

                </button>
             </div> */}

            <div className=" flex justify-center text-center space-x-2">

            {/* <a 
                className="p-5 bg-salmon font-semibold text-black mt-5 hover:bg-salmon hover:text-black transition duration-500 rounded"
                href="/book" >                            
                    Back
                </a> */}
     
            <Link
                to="/checkoutform"
             >
                <button type="button" className='w-full rounded-lg p-5 bg-mint w-5/6 font-semibold text-black mt-5 text-md hover:bg-salmon hover:text-black transition duration-500'
                onClick={()=> handleClick()}>
                Next

                </button>
             
             </Link>

            </div>                
                    
          
          </div>
       
    )

}

export default Appointments;