import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addToDropOff, addToLineItems } from "../../state";
import moment, { duration } from "moment/moment";

const Summary = () => {

    useEffect(() => {
        calcTotal();
      }, []);

    const pets = useSelector(state=> state.cart.pets)
    const days = useSelector(state=> state.cart.days)
    const service = useSelector(state=> state.cart.cart)
    const dates = useSelector(state=> state.cart.dates)
    const times = useSelector(state=> state.cart.times)
    const pickUp = useSelector(state=> state.cart.pickUp)
    const dropOff = useSelector(state=> state.cart.dropOff)

    const serviceName = service[service.length-1].services.name;
    const pickUpFee = pickUp[dropOff.length-1].pickUp;
    const dropOffFee = dropOff[dropOff.length-1].dropOff;
    const serviceId = service[service.length-1].services.serviceId;
    const numPets = pets[pets.length-1].numPets;
    const numDays = days[days.length-1].numDays;
    const servicePrice = service[service.length-1].services.price;
    const startDate = dates[dates.length-1].dates.pickUpDate.toLocaleDateString();
    const endDate = dates[dates.length-1].dates.endDate.toLocaleDateString();
    const startTime =times[times.length-1];
    const endTime = times[times.length-1];
    const numNights = numDays - 1;
    const addPetBoardingFee = 15;
    const addPetDaycareFee = 15;
    const dispatch = useDispatch();
    const [duration, setDuration] = useState(moment.duration());

    const cartState = localStorage.getItem("cart");  
    const petState = localStorage.getItem("pets");  

    const getWalkDuration = useSelector(state=> state.cart.walkDuration);
    const walkDuration = getWalkDuration[getWalkDuration.length-1].walkTime;


    // const addPetDaycareId = "price_1O6NgtFdp7FLr2zY5eWa2a0B"; TEST
    const addPetDaycareId = "price_1O9W9WFdp7FLr2zYK1Qhv8xg"; // PROD
  
    // const addPetBoardId = "price_1O36XvFdp7FLr2zYE6Q5coq0"; TEST
    const addPetBoardId = "price_1O9WFCFdp7FLr2zYkovzLZml"; //PROD


    // const addPetId = "price_1O36XUFdp7FLr2zYWsZI4hfB"; TEST
    const addPetId = "price_1O9WGwFdp7FLr2zYR45yPXcq"; // PROD


    // const extendedCareRateShort = "price_1O3Pl0Fdp7FLr2zYanZSev4o"; TEST
    // const extendedCareRateLong = "price_1O3PlmFdp7FLr2zYml86pUNW"; TEST

    const extendedCareRateShort = "price_1O9WDuFdp7FLr2zYetsYZn5n";// PROD
    const extendedCareRateLong = "price_1O9WCBFdp7FLr2zYncCE34LB"; //PROD
  
    // const extendedDaycareRate = "price_1O6zAjFdp7FLr2zYl3de6MqU"; TEST

    const pickUpFeeId = "price_1PAyViFdp7FLr2zYSlFgf1Iz"; // PROD
    const dropOffFeeId = "price_1PAyVXFdp7FLr2zYKsUIOONq"; // PROD
    
    const extendedDogWalk = "price_1PihbYFdp7FLr2zYbxoCPiS2"  // PROD

    


    const [total, setTotal] = useState(0);

    const calcTotal = () => {

        // console.log("drop off " + JSON.stringify(dropOff[0].dropOff))
  
    

        // stripe checkout items
        let lineItems = {
            price: 0,
            quantity: 0,
 
          }

         
        if (serviceName === "Boarding") {

           
            if (numNights == 0 ) {
                lineItems = {
                    price: serviceId,
                    quantity: numNights+1,
          
                  }
                  dispatch(addToLineItems(lineItems));
            } 

            if ( numNights > 0) {

                lineItems = {
                    price: serviceId,
                    quantity: numNights,
                 
                  }
                  dispatch(addToLineItems(lineItems));
            }
            if (numPets > 1) {
                lineItems = {
                    price: addPetBoardId,
                    quantity: Number(numPets-1) * numNights,
          
                  }
                  dispatch(addToLineItems(lineItems));
                  
            }

    
          }  else {

            lineItems = {
                price: serviceId,
                quantity: numDays,
            
                }
                dispatch(addToLineItems(lineItems));

                if (numPets > 1 && serviceName === "Daycare") {
                    lineItems = {
                        price: addPetDaycareId,
                        quantity: Number(numPets-1) * numDays,
              
                      }
                      dispatch(addToLineItems(lineItems));
                } 

                if ((numPets > 1 && serviceName === "Drop in Visit") || (numPets > 1 && serviceName === "Dog Walk"))  {
                    lineItems = {
                        price: addPetId,
                        quantity: Number(numPets-1) * numDays,
              
                      }
                      dispatch(addToLineItems(lineItems));
                

            }

            if ((numPets > 1 && serviceName === "Dog Walk"))  {

                lineItems = {
                    price: addPetId,
                    quantity: Number(numPets-1) * numDays,
          
                  }
                  dispatch(addToLineItems(lineItems));
            

        }
        }
            
    
        // summary total

        let val = servicePrice * numDays;

    
        
            if (dropOffFee === "Yes") {
            
                val += 25;
                lineItems = {
                    price: dropOffFeeId,
                    quantity: 1,
          
                  }
                  dispatch(addToLineItems(lineItems));
    
            }
    
            if ((pickUpFee === "Yes")) {
    
                val += 25;
                lineItems = {
                    price: pickUpFeeId,
                    quantity: 1,
          
                  }
                  dispatch(addToLineItems(lineItems));
    
    
            }    

            if ((walkDuration === "60 mins")) {
    
                val += 10;
                lineItems = {
                    price: extendedDogWalk,
                    quantity: 1,
          
                  }
                  dispatch(addToLineItems(lineItems));
    
    
            }    


      
        

 

        if ((serviceName === "Boarding")  && numDays > 1) {
        
            val -= servicePrice;
            val -= ((numPets-1)*addPetBoardingFee)  
           
        }

        if (serviceName === "Daycare" && (numPets < 2 )) {
            val -= (numPets-1)*addPetDaycareFee;
        }        

        // additional pets
        if (((serviceName === "Drop in Visit" && numPets > 1 )  || (serviceName === "Dog Walk") && numPets > 1)) {
            val += (5 * (numPets - 1)) * (numDays);
        }

        if ((serviceName === "Boarding")  || (serviceName === "Daycare") && numPets > 1) {
            val += (15 * (numPets - 1)) * (numDays);

        }
        console.log(walkDuration)
        

        {/* Extended hours */}

              
            let start = moment(startTime.startTime, 'hh:mm:A');
            let end = moment(endTime.endTime, 'hh:mm:A');
            setDuration(moment.duration(end.diff(start)));
            let elapsedHours = moment.duration(end.diff(start))

            // console.log(elapsedHours.hours());


        if (serviceName === "Boarding") {
            if (numNights > 0 && (elapsedHours.hours() > 3 && elapsedHours.hours() < 8) ) {
                val += 25
                lineItems = {
                    price: extendedCareRateShort,
                    quantity: 1,
          
                  }
                  dispatch(addToLineItems(lineItems));
            }
            if ( numNights > 0 && (elapsedHours.hours())>= 8 ) {
                val += 50
                lineItems = {
                    price: extendedCareRateLong,
                    quantity: 1,
          
                  }
                  dispatch(addToLineItems(lineItems));
            }

            if ( numNights == 0 && (elapsedHours.hours()) >= 10  ) {
                val += 25
                lineItems = {
                    price: extendedCareRateShort,
                    quantity: 1,
          
                  }
                  dispatch(addToLineItems(lineItems));
            }

            

          
        }

        // if (serviceName === "Daycare") {

   
        //     if ((Math.abs(elapsedHours.hours())) >= 10) {
        //         val += 25
        //         lineItems = {
        //             price: extendedDaycareRate,
        //             quantity: 1,
          
        //           }
        //           dispatch(addToLineItems(lineItems));
            
        //     }
 
        // }

        setTotal(val)        
    }


    return(

        <div className="bg-white relative max-w-sm h-auto flex flex-col text-left justify-start p-4 rounded-lg shadow-md">
        <p className="text-xl font-bold pb-3">Summary</p>
  
        <ul className="font-semibold text-md space-y-2">
          <li>Service: <span>{serviceName}</span></li>
          <li>
            Start: <span>{startDate}</span> at <span>{startTime?.startTime}</span>
          </li>
          <li>
            End: <span>{endDate}</span> at <span>{endTime?.endTime}</span>
          </li>
  
          {serviceName === "Boarding" ? (
            <li>Nights: <span>{numNights}</span></li>
          ) : (
            <li>Days: <span>{numDays}</span></li>
          )}
  
          <li>Pets: <span>{numPets}</span></li>
          <li>Pick Up: <span>{pickUpFee}</span></li>
          <li>Drop Off: <span>{dropOffFee}</span></li>
          <li className="font-semibold">Total: <span>${total}</span></li>
        </ul>
      </div> 
    )

}

export default Summary;