import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const About = () => {
    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

    return (
        <section
            id="about"
            className="md:flex md:justify-between md:items-center gap-16 py-12 w-5/6 mx-auto pb-16" // Added pb-16 to ensure space for the footer
        >
            {/* IMAGE */}
            <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16">
                {isAboveMediumScreens ? (
                    <div>
                        <img
                            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-t-[400px]"
                            src="../assets/steph-profile-img.png"
                            alt="profile-img"
                        />
                    </div>
                ) : (
                    <img
                        className="z-10 max-w-[350px] md:max-w-[600px] rounded-t-[400px]"
                        src="../assets/steph-profile-img.png"
                        alt="profile-img"
                    />
                )}
            </div>

            {/* MAIN TEXT */}
            <div className="z-10 basis-2/5 mt-2 md:mt-32">
                <motion.div
                    initial="hidden"
                    whileInView={"visible"}
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 1, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <p className="text-6xl font-playfair z-10 text-center md:text-start text-salmon">
                        <span className="font-euphoria">About {""} </span>
                    </p>

                    <p className="mt-10 mb-7 text-lg text-center md:text-start text-black font-semibold">
                        Hi, I’m Stephanie. I’m a passionate dog lover of all breeds and sizes with years of professional experience dog walking, pet sitting, and home boarding. I also have experience with cats, senior dogs, and pets with special needs. I don’t have any pets of my own so this allows me to dedicate my full
                        attention to each client. I typically work with one family at a time to ensure personalized care, though I’m happy to accommodate multiple families if we arrange ahead of time. In addition to home boarding I also offer drop-in visits and one-time or regular dog walks.
                        My goal is to accommodate your pet’s unique needs and provide them with a comfortable and loving retreat while you’re away!
                        <br />
                        <br />
                        My calendar shows my boarding availability. Please feel free to reach out directly with any questions!
                    </p>

                    <div className="bg-mint relative max-w-[400px] h-[450px] flex flex-col text-start md:text-start justify-start p-12 rounded">
                        <ul className="list-disc font-semibold text-lg ">
                            <li>Free meet-and-greets before all bookings to meet you and your pet</li>
                            <br />
                            <li>Photo and video updates are sent throughout all boardings, walks, and visits</li>
                            <br />
                            <li>Single family home with spacious fenced-in backyard to ensure plenty of exercise and accessible potty breaks </li>
                            
                            
                        </ul>
                    </div>
                </motion.div>
            </div>

            {/* Optionally, add a Footer component below */}
            {/* <Footer /> */}
        </section>
    );
};

export default About;
