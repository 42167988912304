import React from "react";
import { motion } from "framer-motion";


const ImageCollage = () => {



  const images = [
    
    "../assets/pet_photos/IMG_1153.jpg", // 1
    "../assets/pet_photos/IMG_1154.jpg",
    "../assets/pet_photos/IMG_1156.jpg",
    "../assets/pet_photos/IMG_1157.jpg",
    "../assets/pet_photos/IMG_1158.jpg",
    "../assets/pet_photos/IMG_2135.jpg",
    "../assets/pet_photos/IMG_2316.jpg",
    "../assets/pet_photos/IMG_2386.jpg",
    "../assets/pet_photos/IMG_2805.jpg",
    "../assets/pet_photos/IMG_3163.jpg",
    "../assets/pet_photos/IMG_3443.jpg", // 11
    "../assets/pet_photos/IMG_3453.jpg",
    "../assets/pet_photos/IMG_4403.jpg",
    "../assets/pet_photos/IMG_4835.jpg",
    "../assets/pet_photos/IMG_4879.jpg",
    "../assets/pet_photos/IMG_5110.jpg",
    "../assets/pet_photos/IMG_5391.jpg",
    "../assets/pet_photos/IMG_5565.jpg",
    "../assets/pet_photos/IMG_5878.jpg",
    "../assets/pet_photos/IMG_5921.jpg",
    "../assets/pet_photos/IMG_9360.jpg",
    "../assets/pet_photos/IMG_6057.jpg",
    "../assets/pet_photos/IMG_6373.jpg",
    "../assets/pet_photos/IMG_6582.jpg",
    "../assets/pet_photos/IMG_6653.jpg",
    "../assets/pet_photos/IMG_6950.jpg",
    "../assets/pet_photos/IMG_7143.jpg",
    "../assets/pet_photos/IMG_7243.jpg",
    "../assets/pet_photos/IMG_7822.jpg",
    "../assets/pet_photos/IMG_7932.jpg",
    "../assets/pet_photos/IMG_8060.jpg",
    "../assets/pet_photos/IMG_8329.jpg",
    "../assets/pet_photos/IMG_8614.jpg",
    "../assets/pet_photos/IMG_8924.jpg",
    "../assets/pet_photos/IMG_9498.jpg",
    "../assets/pet_photos/IMG_7045.jpg",
    "../assets/pet_photos/IMG_8230.jpg",
    "../assets/pet_photos/IMG_9340.jpg",
    "../assets/pet_photos/IMG_5971.jpg",
    "../assets/pet_photos/IMG_3741.jpg",
    "../assets/pet_photos/IMG_7828.jpg",
    "../assets/pet_photos/IMG_2893.jpg",


  ];


  return (

    
    <div className="flex justify-center pt-28">

    {/* <div className="text-6xl font-playfair z-10 text-center md:text-start text-salmon"> 
        <p className="text-6xl font-playfair z-10 text-center md:text-start text-salmon">
          <span className="font-euphoria">Check out my furry friends! {""} </span>
        </p>


      </div> */}
     


      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-4">
        {images.map((src, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            className="overflow-hidden rounded-2xl shadow-lg"
          >
            <img src={src} alt={`Collage image ${index + 1}`} className="w-full h-full object-cover" />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ImageCollage;
