import React from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const Landing = () => {
    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

    return (
        <section id="home" className="md:justify-between md:items-center md:h-full gap-16 py-24 w-full mx-auto md:h-100">
            {/* Banner with Text */}
            <div
                className={`relative w-screen ${isAboveMediumScreens ? "h-screen" : "h-[500px]"}`}
            >
                {/* Background Image */}
                <img
                    src="./assets/banner4.jpg"
                    alt="Banner"
                    className="w-full h-full object-cover object-top" // Ensures top of the image stays visible
                />
                {/* Overlay Text */}
                <div className="absolute inset-0 flex flex-col items-center justify-end bg-opacity-30 pb-10 md:pb-16">
                    <h1 className="text-white text-5xl md:text-6xl font-bold text-center px-4 font-euphoria">
                        Steph's Pet Retreat
                    </h1>
                    <p className="mt-5 text-lg px-5 text-center md:text-2xl text-white font-semibold">
                        A pet care service offering home boarding, dog walking & more
                    </p>
                    <a
                        className="p-5 bg-mint font-semibold text-black mt-5 text-md hover:bg-salmon hover:text-black transition duration-500 rounded"
                        href="/book"
                    >
                        Book a Service!
                    </a>
                </div>
            </div>

            {/* IMAGE Animation */}

        </section>
    );
};

export default Landing;
