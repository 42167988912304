// import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const serviceVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },

};

const Service = ({ title, text }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-100 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-black font-semibold`;
  const serviceTitle = title.toLowerCase();

  return (
    <motion.div variants={serviceVariant} className="relative" >
            <div className="absolute  h-10 w-full opacity-100 transition duration-500
     z-30 flex flex-col justify-center items-center text-center p-16 text-mint font-bold">
              <p className="text-xl font-playfair">{title}</p>
            </div>
      <div className={overlayStyles}>
        <p className="text-xl font-playfair">{title}</p>        
        <p className="mt-7">
          {text} 
        </p>
      </div>
      <img className="" src={`../assets/${serviceTitle}.png`} alt={serviceTitle} />
    </motion.div>
  );
};

const Services = () => {
  return (
    <section id="services" className="pt-32 pb-24 w-5/6 mx-auto">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            {/* <span className="text-salmon">Services</span> */}
          </p>
          {/* <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div> */}
        </div>
        <p className="mt-10 mb-10">
     
        </p>
      </motion.div>

      <p className="font-playfair text-4xl mb-5 text-salmon">
      <span className="font-euphoria text-6xl">Services {""} </span> 
      </p>


      <br />



      {/* serviceS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* ROW 1 */}
          <div
            className="flex justify-center text-center items-center p-10 bg-mint
              max-w-[550px] max-h-[400px] text-xl font-playfair font-semibold"
          >
            Select a Service for pricing and details
          </div>

          
          <Service 
            title="Meet and Greet"
            text="Free meet-and-greets to meet you and your pets." />

          <Service           
              title="Walking"
              text="$25 (30 mins), $35 (1 hour) ⁃ $5 per additional dog. Come to your home to take your dog out for a walk and a potty break." />
               


          {/* ROW 2 */}
 
          <Service 
               title="Daycare"
              text="$40 / day ⁃ $15 per additional pet. Up to an 8 hour daytime stay in my home." />
          <Service title="Boarding" 
              text="
              $50 / night ⁃ $15 per additional pet. Extended overnight care in my home." />

          <Service 
              title="Drop in Visits" 
              text="$20 (20 mins) ⁃ $5 per additional pet. Arrive at your home to care for your pets. This includes feeding, 
              administering medications, potty breaks, and lots of cuddles and treats!"/>

          {/* ROW 3 */}
          {/* <service title="service 6" />
          <service title="service 7" /> */}
          {/* <div
            className="flex justify-center text-center items-center p-10 bg-mint
              max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            SMOOTH USER EXPERIENCE
          </div> */}
        </motion.div>
      </div>
    </section>
  );
};

export default Services;